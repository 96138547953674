.career-positions
    padding-top: 40px
    padding-bottom: 40px
    .career-positions__container
        .career-positions___title
            font-size: 30px
            font-weight: 700
            line-height: 40px

            margin-bottom: 70px

        .career-positions___top-description
            font-size: 22px
            line-height: 35px
            font-weight: 500

            margin-bottom: 60px

        .career-positions___block-container
            display: flex
            flex-direction: row
            flex-wrap: wrap

            margin-bottom: 60px

            .career-positions___block-single
                padding-left: 40px
                padding-right: 40px

                margin-bottom: 40px

                display: flex
                flex-direction: column

                width: calc(100% / 3)

                .career-positions___block-single-title
                    display: flex
                    flex-grow: 1

                    align-items: center
                    justify-content: center

                    font-size: 30px
                    line-height: 45px
                    font-weight: 700

                    padding-right: 30px
                    padding-bottom: 40px
                    padding-top: 40px

                .career-positions___block-single-read-more
                    .career-positions___block-single-read-more-input
                        display: flex
                        flex-direction: row

                    .scroll-to
                        &.not-visible
                            opacity: 0
                            visibility: hidden

        .career-positions___bottom-description
            font-size: 16px
            line-height: 30px
            font-weight: 500

            margin-bottom: 60px

@media screen and (max-width: 991px)
    .career-positions
        padding-top: 20px
        padding-bottom: 20px
        .career-positions__container
            .career-positions___title
                margin-bottom: 50px

            .career-positions___top-description
                margin-bottom: 40px

            .career-positions___block-container
                margin-bottom: 20px

                .career-positions___block-single
                    padding-left: 0
                    padding-right: 0

                    margin-bottom: 50px

                    width: 100%

                    .career-positions___block-single-title
                        padding-left: 0
                        padding-right: 0
                        padding-bottom: 20px
                        padding-top: 30px

                        text-align: center

                    .career-positions___block-single-read-more
                        display: flex
                        align-items: center
                        justify-content: center

                        a
                            text-align: center

            .career-positions___bottom-description
                margin-bottom: 40px

@media screen and (max-width: 720px)
    .career-positions
        .career-positions__container
            .career-positions___title
                font-size: 25px
                line-height: 35px

                margin-bottom: 40px

            .career-positions___top-description
                font-size: 16px
                line-height: 30px

            .career-positions___block-container
                .career-positions___block-single
                    margin-bottom: 30px

                    .career-positions___block-single-title
                        font-size: 20px
                        line-height: 30px

            .career-positions___bottom-description
                font-size: 16px
                line-height: 30px
                font-weight: 500
