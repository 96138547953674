.home
    .home__slider-options
        padding-left: 10px

    .home__slider-container
        position: relative

        .home__slider
            position: relative

            .home__slider-item
                display: inline-flex !important
                align-items: center
                justify-content: center

                width: 100%
                min-height: calc(100vh - 65px)

                .home__slider-inner
                    max-width: 980px

                    .home__slider-controls
                        display: flex
                        flex-direction: row

                        align-items: center
                        justify-content: space-between

                        .count
                            font-size: 14px

                    .home__slider-text
                        color: $white
                        font-size: 85px
                        line-height: 125px
                        font-weight: 700
                        word-break: break-word

                        letter-spacing: 3px

                    .home__slider-link-container
                        display: flex
                        align-items: center
                        justify-content: center

                        .home__slider-link
                            margin-top: 40px

                            opacity: 0.45
                            transition: 0.3s opacity

                            &:hover
                                opacity: 1

                            img
                                width: 26px
                                height: auto

            .slick-arrow
                top: 50%
                transform: translateY(-50%)
                position: absolute
                z-index: 9998

                border: 0
                outline: 0
                background: transparent

                opacity: 0.45
                transition: 0.3s opacity

                .material-icons
                    color: $red
                    font-size: 50px

                &:hover
                    opacity: 1

                &.slick-prev
                    left: 10%

                &.slick-next
                    right: 10%

// Responsive
@media screen and (max-width: 1400px)
    .home
        .home__slider-container
            .home__slider
                .home__slider-item
                    .home__slider-inner
                        max-width: 75%
                        padding-top: 30px
                        padding-bottom: 30px

                        .home__slider-controls

                        .home__slider-text
                            font-size: 70px
                            line-height: 100px

                        .home__slider-link-container

                            .home__slider-link

                .slick-arrow
                    &.slick-prev
                        left: 30px

                    &.slick-next
                        right: 30px

@media screen and (max-width: 991px)
    .home
        .home__slider-container
            .home__slider
                .home__slider-item
                    .home__slider-inner
                        .home__slider-controls

                        .home__slider-text
                            font-size: 50px
                            line-height: 70px

                            text-align: center

                        .home__slider-link-container
                            .home__slider-link
                                margin-top: 25px

                                img
                                    width: 20px
                                    height: auto
                .slick-arrow
                    .material-icons
                        font-size: 30px

                    &.slick-prev
                        left: 20px

                    &.slick-next
                        right: 20px

@media screen and (max-width: 720px)
    .home
        .home__slider-container
            .home__slider
                .home__slider-item
                    .home__slider-inner
                        .home__slider-controls

                        .home__slider-text
                            font-size: 40px
                            line-height: 50px

                        .home__slider-link-container

                            .home__slider-link


@media screen and (max-width: 520px)
    .home
        .home__slider-container
            .home__slider
                .home__slider-item
                    .home__slider-inner
                        .home__slider-controls

                        .home__slider-text
                            font-size: 24px
                            line-height: 32px

                        .home__slider-link-container

                            .home__slider-link

                .slick-arrow
                    &.slick-prev
                        left: 12px

                    &.slick-next
                        right: 12px
