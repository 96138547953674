.contact-us
    padding-top: 90px

    &.submit-success
        .contact-us__form-container
            display: none

        .contact-us__form-success-message-container
            display: block

    .contact-us__title
        margin-bottom: 100px

    .contact-us__desc
        color: $white
        font-family: 'Spartan', sans-serif

        font-size: 22px
        font-weight: 600
        line-height: 50px

        margin-bottom: 60px

    .contact-us__form
        padding: 50px
        margin-bottom: 150px
        border-top: 0.5px solid rgba($red, 0.5)

        .contact-us__interest-selection
            margin-top: 45px
            margin-bottom: 120px

            .form-group--select
                .form-select-options
                    display: flex
                    flex-direction: column
                    position: relative

                    margin-top: 15px
                    padding-left: 75px

                    input
                        top: 0
                        opacity: 0
                        visibility: hidden
                        position: absolute

                    label
                        cursor: pointer
                        font-size: 18px
                        font-weight: 600
                        line-height: 20px

                        display: inline-flex
                        align-items: center
                        margin-bottom: 20px

                        transition: 0.3s all

                        user-select: none

                        &:last-child
                            margin-bottom: 0

                        &:before
                            display: block
                            content: ''

                            width: 20px
                            height: 20px
                            margin-right: 15px

                            flex-shrink: 0

                            border: 1px solid rgba($red, 0.5)

                    input:checked + label
                        color: $red

                        &:before
                            background-image: url('/static/img/arrow-red.svg')
                            background-size: 10px
                            background-repeat: no-repeat
                            background-position: center

                .form-error
                    padding-left: 75px

        .contact-us__description-input
            margin-bottom: 60px

            .form-group__label-container
                display: flex
                align-items: center

                margin-bottom: 10px

                label
                    flex-grow: 1

            textarea.form-control
                height: 220px

    .contact-us__form-success-message--input
        font-size: 22px
        line-height: 30px

        margin-top: 70px
        margin-bottom: 120px

    .contact-us__form-success-message-container
        display: none
        margin-bottom: 180px

        .back-container
            margin-bottom: 50px

            .back-to-contact
                &:before
                    content: ''
                    display: inline-flex
                    height: 15px
                    width: 12px
                    margin-top: -2px
                    margin-right: 3px
                    background-image: url(/static/img/arrow-red.svg)
                    background-repeat: no-repeat
                    background-size: 12px

                    transform: rotate(180deg)

                span
                    font-size: 14px
                    line-height: 28p
                    font-weight: 600

                    color: $white
                    transition: 0.3s color

                &:hover
                    span
                        color: $red

        .icon-container
            display: flex
            align-items: center
            justify-content: center

            padding-top: 50px
            padding-bottom: 60px
            .material-icons
                color: $red
                font-size: 85px

    .contact-us__bottom
        .contact-us__company-info-container
            margin-bottom: 90px
            .contact-us__company-name
                color: $red
                font-size: 22px
                font-weight: 700
                line-height: 30px

                margin-bottom: 15px

            .contact-us__company-info
                display: flex
                flex-direction: row

                .contact-us__company-address,
                .contact-us__company-oib,
                .contact-us__company-email
                    width: calc(100%/3)

                    font-size: 18px
                    color: $white
                    line-height: 30px
                    font-weight: 500

                .contact-us__company-address
                    text-align: left
                    border-right: 1px solid $white

                .contact-us__company-oib
                    text-align: center
                    border-right: 1px solid $white

                .contact-us__company-email
                    text-align: right

                a.contact-us__company-email
                    transition: 0.3s color
                    &:hover, &:active, &:focus
                        color: $red
                        text-decoration: none

        .contact-us__bottom-arrows
            width: 100%
            height: 195px

            background-image: url("/static/img/arrow-single.png")
            background-repeat: repeat-x
            background-size: contain
            //background-position-y: 60px

@media screen and (max-width: 1024px)
    .contact-us
        padding-top: 70px

        .contact-us__title
            font-size: 50px
            line-height: 60px

            margin-bottom: 60px

        .contact-us__form
            padding: 40px
            margin-bottom: 120px

        .contact-us__bottom
            .contact-us__company-info-container
                .contact-us__company-name
                    text-align: center
                    margin-bottom: 20px

                .contact-us__company-info
                    display: flex
                    flex-direction: column

                    .contact-us__company-address,
                    .contact-us__company-oib,
                    .contact-us__company-email
                        border: 0 !important
                        text-align: center

                        width: 100%
                        margin-bottom: 15px

@media screen and (max-width: 991px)
    .contact-us
        .contact-us__form
            padding: 20px
            padding-top: 30px

            .contact-us__top-fields
                .form-group
                    margin-bottom: 40px

            .contact-us__interest-selection
                margin-top: 0
                margin-bottom: 50px

            .contact-us__description-input
                margin-bottom: 30px

                textarea
                    height: 100px !important

        .contact-us__bottom
            .contact-us__company-info-container
                .contact-us__company-name
                    text-align: center
                    margin-bottom: 20px

                .contact-us__company-info
                    display: flex
                    flex-direction: column

                    .contact-us__company-address,
                    .contact-us__company-oib,
                    .contact-us__company-email
                        border: 0 !important
                        text-align: center

                        width: 100%
                        margin-bottom: 15px

@media screen and (max-width: 720px)
    .contact-us
        padding-top: 40px

        .contact-us__title
            font-size: 40px
            line-height: 50px

        .contact-us__form
            padding: 10px
            padding-top: 30px

    .contact-us__interest-selection
        .form-group--select
            .form-select-options
                padding-left: 0 !important

                label
                    font-size: 14px !important

        .form-error
            padding-left: 0 !important

@media screen and (max-width: 520px)
    .contact-us
        .contact-us__title
            font-size: 30px
            line-height: 40px