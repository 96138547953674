// Simple bootstrap overides
.container
    margin: 0 auto
    padding-left: 0
    padding-right: 0

    max-width: 1200px

.row
    margin-left: -$grid_gutters !important
    margin-right: -$grid_gutters !important

[class*='col-']
    padding-left: $grid_gutters !important
    padding-right: $grid_gutters !important

@media screen and (max-width: 1250px)
    .container
        max-width: 100%
        padding-left: 50px
        padding-right: 50px

@media screen and (max-width: 1024px)
    .row
        margin-left: -$grid_gutters_tablet !important
        margin-right: -$grid_gutters_tablet !important

    [class*='col-']
        padding-left: $grid_gutters_tablet !important
        padding-right: $grid_gutters_tablet !important

@media screen and (max-width: 720px)
    .container
        padding-left: 30px
        padding-right: 30px

    .row
        margin-left: -$grid_gutters_mobile !important
        margin-right: -$grid_gutters_mobile !important

    [class*='col-']
        padding-left: $grid_gutters_mobile !important
        padding-right: $grid_gutters_mobile !important