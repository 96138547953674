.description-block
    padding-top: 40px
    padding-bottom: 40px
    .description-block__container
        .description-block___title
            font-size: 30px
            font-weight: 700
            line-height: 40px

            margin-bottom: 80px

        .description-block__blocks
            padding-left: 60px
            padding-right: 60px

            .description-block__single
                margin-bottom: 100px

                &:last-child
                    margin-bottom: 0

                .description-block__single-title
                    color: $red
                    font-weight: 700
                    font-size: 25px
                    line-height: 45px

                    margin-bottom: 20px

                .description-block__single-description
                    font-size: 20px
                    font-weight: 400
                    line-height: 35px

                    p
                        font-size: 20px
                        font-weight: 400
                        line-height: 35px

                        span.arrow
                            &:before
                                content: ' '
                                display: inline-flex

                                height: 18px
                                width: 15px

                                margin-right: 6px

                                background-image: url('/static/img/arrow-red.svg')
                                background-repeat: no-repeat
                                background-size: 13px

@media screen and (max-width: 1250px)
    .description-block
        padding-top: 40px
        padding-bottom: 40px
        .description-block__container
            .description-block___title
                margin-bottom: 40px

            .description-block__blocks
                padding-left: 40px
                padding-right: 40px

                .description-block__single
                    margin-bottom: 50px

                    .description-block__single-title
                        margin-bottom: 15px

@media screen and (max-width: 991px)
    .description-block
        padding-top: 20px
        padding-bottom: 20px
        .description-block__container
            .description-block__blocks
                padding-left: 20px
                padding-right: 20px

                .description-block__single
                    margin-bottom: 40px

                    .description-block__single-title
                        margin-bottom: 15px

@media screen and (max-width: 720px)
    .description-block
        padding-top: 40px
        padding-bottom: 40px
        .description-block__container
            .description-block___title
                font-size: 25px
                line-height: 35px

            .description-block__blocks
                padding-left: 0px
                padding-right: 0px

                .description-block__single
                    margin-bottom: 30px

                    .description-block__single-title
                        font-size: 20px
                        line-height: 35px

                        margin-bottom: 12px

                    .description-block__single-description
                        font-size: 16px
                        line-height: 24px

                        p
                            font-size: 16px
                            line-height: 24px

                            span.arrow
                                &:before
                                    height: 15px
                                    width: 12px
