$blocks_gutter: 110px
.text-block
    padding-top: 50px
    padding-bottom: 50px
    .text-block__container
        margin-top: - $blocks_gutter / 2
        margin-bottom: - $blocks_gutter / 2

        .text-block__inner
            display: flex
            flex-direction: row
            flex-wrap: wrap

            margin-left: -$blocks_gutter
            margin-right: -$blocks_gutter

            .text-block__item
                width: calc(50% - $blocks_gutter * 2)

                padding-bottom: 15px

                margin-left: $blocks_gutter
                margin-right: $blocks_gutter

                margin-top: $blocks_gutter / 2
                margin-bottom: $blocks_gutter / 2

                border-bottom: 1px solid rgba($red, 0.5)

                .text-block__headline
                    margin-bottom: 25px

                .text-block__description
                    font-size: 20px
                    font-weight: 400
                    line-height: 35px

                    display: inline-flex

                    p
                        font-size: 20px
                        font-weight: 400
                        line-height: 35px

                        span.arrow
                            &:before
                                content: ' '
                                display: inline-flex

                                height: 18px
                                width: 15px

                                margin-right: 6px

                                background-image: url('/static/img/arrow-red.svg')
                                background-repeat: no-repeat
                                background-size: 13px

@media screen and (max-width: 1440px)
    $blocks_gutter_tablet: 50px
    .text-block
        .text-block__container
            margin-top: - $blocks_gutter_tablet / 2
            margin-bottom: - $blocks_gutter_tablet / 2

            .text-block__inner
                margin-left: -$blocks_gutter_tablet
                margin-right: -$blocks_gutter_tablet

                .text-block__item
                    width: calc(50% - $blocks_gutter_tablet * 2)

                    margin-left: $blocks_gutter_tablet
                    margin-right: $blocks_gutter_tablet

                    margin-top: $blocks_gutter_tablet / 2
                    margin-bottom: $blocks_gutter_tablet / 2

                    .text-block__headline

                    .text-block__description

@media screen and (max-width: 991px)
    .text-block
        .text-block__container
            margin-top: 0
            margin-bottom: 0

            .text-block__inner
                margin-left: 0
                margin-right: 0

                .text-block__item
                    width: 100%

                    margin-left: 0
                    margin-right: 0

                    margin-top: 0px
                    margin-bottom: 40px

                    .text-block__headline
                        margin-bottom: 20px

                    .text-block__description

@media screen and (max-width: 720px)
    .text-block
        .text-block__container
            .text-block__inner
                .text-block__item
                    width: 100%

                    margin-left: 0
                    margin-right: 0

                    margin-top: 0px
                    margin-bottom: 40px

                    .text-block__headline
                        margin-bottom: 15px

                    .text-block__description
                        p
                            font-size: 16px
                            line-height: 24px

                            span.arrow
                                &:before
                                    height: 15px
                                    width: 12px
