.btn
    border: 0
    border-radius: 0

    background: rgba($red, 0.45)
    color: $white
    font-size: 18px
    line-height: 20px
    font-weight: 700
    text-transform: uppercase

    outline: 0
    box-shadow: none !important
    padding: 18px 18px

    transition: 0.3s all

    @media screen and (max-width: 720px)
        padding: 15px
        font-size: 16px

    &:hover, &:focus, &:active
        color: $white
        background: $red
