.careers
    padding-top: 40px
    padding-bottom: 40px

    @media screen and (max-width: 991px)
        padding-top: 20px
        padding-bottom: 20px

    .careers__container
        .careers__title
            margin-bottom: 50px

            @media screen and (max-width: 991px)
                margin-bottom: 30px

            @media screen and (max-width: 720px)
                margin-bottom: 10px
                font-size: 25px
                line-height: 35px

        &.expanded
            .careers__title,
            .talentlyft__open-position-container
                display: none

        .code__container
            color: $black
            background: $white
            padding: 5px 10px
            border: 2px solid $red

        .hint
            font-size: 16px
            line-height: 20px

            margin-bottom: 20px

        .talentlyft
            width: 100%
            max-width: 100%

            .col-md-6
                padding-left: 50px
                padding-right: 50px

        .talentlyftWidget
            width: 100%
            max-width: 100%

            margin: 0
            padding: 0
            font-family: 'Spartan', sans-serif !important

            // Hide search & filters
            .job-filters
                display: none !important

            // Jobs list
            ul.tl-jobs
                display: flex
                flex-direction: row
                flex-wrap: wrap

                $gutter: 60px

                padding: 0
                margin-top: -$gutter
                margin-left: -$gutter
                margin-right: -$gutter
                margin-bottom: -$gutter

                .tl-job
                    color: $white !important
                    margin-top: $gutter
                    margin-bottom: $gutter
                    margin-left: $gutter
                    margin-right: $gutter

                    padding: 0
                    padding-bottom: 15px

                    border-bottom: 1px solid rgba($red, 0.5)

                    transition: 0.3s all
                    width: calc(50% - $gutter * 2)

                    &:hover, &:focus, &:active
                        background: transparent
                        background-image: url(/static/img/arrow-single.png)
                        background-repeat: repeat-x
                        background-size: contain

                    .tl-title
                        display: flex
                        flex-direction: column

                        font-family: 'Spartan', sans-serif !important

                        .tl-title-text
                            color: $white
                            font-weight: 600
                            font-size: 22px
                            line-height: 28px

                            width: 100%
                            display: flex !important
                            flex-grow: 1
                            margin-bottom: 20px

                        .tl-address
                            width: 100%
                            display: flex !important

                            i
                                img
                                    display: none

                                &:before
                                    content: ''
                                    display: inline-flex
                                    height: 15px
                                    width: 12px
                                    margin-top: 4px
                                    margin-right: 6px
                                    background-image: url(/static/img/arrow-red.svg)
                                    background-repeat: no-repeat
                                    background-size: 12px

                            span
                                margin-left: 0

                                color: $white
                                font-weight: 600
                                font-size: 14px
                                line-height: 28px

                @media screen and (max-width: 991px)
                    margin: 0

                    .tl-job
                        width: 100%

                        margin-left: 0
                        margin-right: 0

                        margin-top: 40px
                        margin-bottom: 40px

                @media screen and (max-width: 720px)
                    .tl-job
                        margin-top: 30px
                        margin-bottom: 30px

                        .tl-title-text
                            font-size: 18px !important
                            line-height: 20px !important
                            margin-bottom: 12px !important

                        .tl-address
                            span
                                font-weight: 400 !important

            // Job description
            .job-description-container
                padding-left: 0
                padding-right: 0

                .tl-back
                    display: flex
                    flex-direction: row
                    align-items: center

                    margin-bottom: 45px
                    font-family: 'Spartan', sans-serif !important

                    i
                        margin-right: 3px

                        img
                            display: none

                        &:before
                            content: ''
                            display: inline-flex
                            height: 15px
                            width: 12px
                            margin-top: -2px
                            background-image: url(/static/img/arrow-red.svg)
                            background-repeat: no-repeat
                            background-size: 12px

                            transform: rotate(180deg)

                    span
                        margin-left: 0

                        font-weight: 600
                        font-size: 14px
                        line-height: 28px

                        color: $white
                        transition: 0.3s color

                    &:hover
                        span
                            color: $red

                .tl-desc-title
                    font-size: 60px
                    font-weight: 700
                    line-height: 80px

                    //margin-bottom: 60px
                    margin-bottom: 12px

                    @media screen and (max-width: 991px)
                        font-size: 50px
                        line-height: 60px

                    @media screen and (max-width: 720px)
                        font-size: 40px
                        line-height: 50px

                    @media screen and (max-width: 520px)
                        font-size: 30px
                        line-height: 40px

                    .tl-desc-title-address
                        display: none

                .btn-primary
                    border: 0
                    border-radius: 0

                    background: rgba($red, 0.45) !important
                    color: $white
                    font-size: 18px
                    font-weight: 700
                    line-height: 20px
                    text-transform: uppercase

                    outline: 0
                    box-shadow: none
                    padding: 18px 18px

                    transition: 0.3s all

                    margin-top: 60px

                    @media screen and (max-width: 720px)
                        padding: 15px
                        font-size: 16px

                        margin-top: 40px

                    &:hover, &:focus, &:active
                        background: $red !important

                p
                    font-family: "Spartan", sans-serif !important
                    font-size: 22px !important
                    line-height: 35px
                    strong
                        font-weight: 800

                    a
                        font-size: inherit !important
                        color: rgba($red, 0.45) !important
                        text-decoration: none !important
                        font-family: "Spartan", sans-serif !important

                        transition: 0.3s color

                        &:hover, &:focus, &:active
                            color: $red !important

                    @media screen and (max-width: 1024px)
                        font-size: 20px !important
                        line-height: 40px !important

                    @media screen and (max-width: 520px)
                        font-size: 16px !important
                        line-height: 30px !important

                ul
                    margin-top: 30px
                    list-style: none

                    ul
                        padding-left: 50px

                        @media screen and (max-width: 520px)
                            padding-left: 25px

                    li
                        color: $white
                        font-size: 18px
                        font-weight: 500
                        line-height: 26px

                        margin-bottom: 40px

                        padding-left: 20px
                        position: relative

                        a
                            font-size: inherit !important
                            color: rgba($red, 0.45) !important
                            text-decoration: none !important
                            font-family: "Spartan", sans-serif !important

                            transition: 0.3s color

                            &:hover, &:focus, &:active
                                color: $red !important

                        @media screen and (max-width: 720px)
                            margin-bottom: 25px

                        @media screen and (max-width: 520px)
                            font-size: 16px !important
                            line-height: 22px !important
                            margin-bottom: 20px

                        &:before
                            top: 0
                            left: 0
                            position: absolute

                            content: ''
                            display: flex

                            height: 15px
                            width: 12px
                            margin-top: 2px

                            background-image: url(/static/img/arrow-red.svg)
                            background-repeat: no-repeat
                            background-size: 12px

                            flex-shrink: 0

                hr
                    display: none

                &.apply-form-visible
                    p, a, ul, li
                        display: none

                    .tl-desc-title

                    .tl-back
                        display: flex

        // Application form
        form.talentlyft
            &.visible
                .application-form
                    font-family: "Spartan", sans-serif !important

                    margin-top: 40px
                    padding-top: 50px

                    border-top: 1px solid rgba($red, 0.45)

                    fieldset
                        legend
                            display: none

                    a
                        font-size: 16px
                        font-weight: 700
                        color: $red !important
                        i
                            display: none

                    @media screen and (max-width: 720px)
                        padding-left: 15px !important
                        padding-right: 15px !important

                        .col-md-6
                            //padding-left: 0px !important
                            //padding-right: 0px !important

                    .form-group
                        margin-bottom: 40px

                        .control-label
                            span
                                font-size: 22px
                                font-weight: 600
                                line-height: 40px

                                &.required
                                    font-size: 14px
                                    margin-left: 3px

                            @media screen and (max-width: 1024px)
                                span
                                    font-size: 18px
                                    line-height: 20px

                                    &.required
                                        font-size: 12px

                        .form-control
                            border: 0
                            border-radius: 0
                            border-bottom: 1px solid rgba($red, 0.45)
                            background: transparent

                            padding-left: 0

                            height: 57px
                            font-size: 20px
                            color: rgba($white, 0.9)

                            @media screen and (max-width: 1024px)
                                height: 45px
                                font-size: 18px

                            &.tl-error
                                box-shadow: unset !important
                                border: 2px solid $red !important

                            &::placeholder
                                color: $black

                        textarea.form-control
                            height: 220px

                            @media screen and (max-width: 991px)
                                height: 140px

                            @media screen and (max-width: 720px)
                                height: 120px

                        &.checkbox-type
                            .tl-checkbox
                                padding-left: 8%
                                margin-bottom: 5px
                                label
                                    cursor: pointer
                                    position: relative
                                    input
                                        opacity: 0
                                        visibility: hidden

                                        position: absolute

                                    span
                                        color: $white
                                        font-size: 18px
                                        line-height: 20px
                                        font-weight: 700

                                        display: inline-flex

                                        transition: 0.3s all
                                        user-select: none

                                        &:before
                                            display: block
                                            content: ''

                                            width: 20px
                                            height: 20px
                                            margin-top: -2px
                                            margin-right: 15px

                                            flex-shrink: 0

                                            border: 1px solid rgba($red, 0.5)

                                    input:checked + span
                                        color: $red

                                        &:before
                                            background-image: url('/static/img/arrow-red.svg')
                                            background-size: 10px
                                            background-repeat: no-repeat
                                            background-position: center

                                @media screen and (max-width: 991px)
                                    margin-bottom: 10px
                                    margin-top: 15px
                                    label
                                        span
                                            font-size: 16px
                                            font-weight: 400

                    #talentlyftValidationSummary
                        border: 0
                        box-shadow: unset
                        border-radius: 0
                        background: rgba($red, 0.5) !important

                        li
                            color: $white

                            @media screen and (max-width: 991px)
                                font-size: 14px !important

                    .btn-primary
                        border: 0
                        border-radius: 0

                        background: rgba($red, 0.45) !important
                        color: $white
                        font-size: 18px
                        font-weight: 700
                        line-height: 20px
                        text-transform: uppercase

                        outline: 0
                        box-shadow: none
                        padding: 18px 18px

                        transition: 0.3s all

                        margin-top: 20px

                        @media screen and (max-width: 720px)
                            padding: 15px
                            font-size: 16px

                            margin-top: 0

                        &:hover, &:focus, &:active
                            background: $red !important

        .talentlyft__open-position-container
            padding-top: 50px
            margin-bottom: 20px

            @media screen and (max-width: 991px)
                padding-top: 30px

            @media screen and (max-width: 720px)
                padding-top: 0

            .talentlyft__open-position_arrow-container
                display: flex
                align-items: center
                justify-content: center

                margin-bottom: 80px

                @media screen and (max-width: 991px)
                    margin-bottom: 50px

                @media screen and (max-width: 720px)
                    margin-bottom: 40px

                .talentlyft__open-position_arrow
                    cursor: pointer

                    img
                        width: 54px
                        height: auto

                        object-fit: contain

                        opacity: 0.45
                        transition: 0.3s all

                        @media screen and (max-width: 720px)
                            width: 35px

                    &:hover
                        img
                            opacity: 1

            .talentlyft__open-position-description
                font-size: 22px
                line-height: 35px
                font-weight: 500

                padding-top: 15px
                margin-bottom: 50px

                @media screen and (max-width: 720px)
                    font-size: 16px
                    line-height: 30px
                    font-weight: 500

            .open-job-button
                color: $white !important

                @media screen and (max-width: 720px)
                    padding: 15px
                    font-size: 16px

                &:hover, &:focus, &:active
                    background: $red !important

        .job-applied
            padding: 0
            margin-top: 80px
            margin-bottom: 80px
            .container
                .successfully-msg
                    &:before
                        content: '\e86c'
                        font-family: 'Material Icons'

                        display: block
                        color: $red
                        font-size: 80px

                    img
                        display: none

                    h4
                        font-size: 25px
                        line-height: 40px

                        padding-top: 20px
                        padding-bottom: 20px

                        @media screen and (max-width: 991px)
                            font-size: 20px
                            line-height: 35px

                        @media screen and (max-width: 520px)
                            font-size: 18px
                            line-height: 25px

                hr
                    display: none