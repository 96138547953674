header
    //height: 60px
    border-bottom: 1px solid $black
    .header__container
        margin: 0 auto
        max-width: 1400px

        display: flex
        flex-direction: row

        padding-top: 18px
        padding-bottom: 18px

        z-index: 99997
        position: relative

        .header__logo
            display: flex
            align-items: center
            svg
                width: 110px
                height: auto

        .header__navigation
            flex-grow: 1

            display: flex
            justify-content: flex-end

            padding-right: 50px
            border-right: 1px solid $white

            .header__link
                color: $white
                font-size: 13px
                font-weight: 500
                line-height: 15px

                margin-right: 40px
                text-decoration: none

                display: flex
                align-items: center

                transition: 0.3s color

                &.active
                    color: $red
                    font-weight: 700

                &:last-child
                    margin-right: 0

                &:hover, &:focus, &:active
                    color: $red

        .language-switch-container
            padding-left: 15px

        .header__navigation--mobile
            display: none
            flex-grow: initial
            flex-direction: column

            position: relative

            padding-right: 0
            border-right: none

            user-select: none

            input[type="checkbox"]
                display: block
                width: 40px
                height: 32px
                position: absolute
                top: -7px
                left: -5px

                cursor: pointer

                opacity: 0 // hide this
                z-index: 2 // and place it over the hamburger

                -webkit-touch-callout: none

            // Just a quick hamburger
            span
                display: block
                width: 33px
                height: 4px
                margin-bottom: 5px
                position: relative

                background: $white
                border-radius: 0px

                z-index: 1

                transform-origin: 4px 0px
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease

                &:first-child
                    transform-origin: 0% 0%

                &:nth-last-child(2)
                    transform-origin: 0% 100%

            // Transform all the slices of hamburger into a crossmark
            input:checked ~ span
                opacity: 1
                transform: rotate(45deg) translate(-2px, -1px)
                background: $red

                // But let's hide the middle one.
                &:nth-last-child(3)
                    opacity: 0
                    transform: rotate(0deg) scale(0.2, 0.2)

                // Last one should go the other direction
                &:nth-last-child(2)
                    transform: rotate(-45deg) translate(0, -1px)

            input:checked ~ .header__navigation--sidebar
                transform: translate(0, 0)

            .header__navigation--sidebar
                position: fixed

                right: 0
                top: 0

                width: 300px
                height: 100vh

                padding: 50px
                padding-top: 100px

                //z-index: 99995

                background: $black
                list-style-type: none
                -webkit-font-smoothing: antialiased

                transform-origin: 0% 0%
                transform: translate(100%, 0)

                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)
                border-left: 1px solid rgba($red, 0.4)

                .header__link
                    font-size: 20px
                    font-weight: 500
                    line-height: 35px

                    margin-bottom: 15px
                    margin-right: 0

                    width: 100%
                    display: inline-block

                    &.active
                        font-weight: 700

    // Fixed header
    &.fixed
        position: fixed
        top: 0
        left: 0
        width: 100%

        background-color: $black
        z-index: 99998

        border-bottom: 1px solid rgba($red, 0.4)
        box-shadow: 4px -17px 20px 9px #6e5555

header.fixed + .main-container
    //padding-top: 60px

@media screen and (max-width: 1440px)
    header
        .header__container
            max-width: 100%
            padding-left: 50px
            padding-right: 50px

@media screen and (max-width: 1024px)
    header
        .header__container
            .header__navigation--desktop
                display: none

            .header__logo
                flex-grow: 1

            .language-switch-container
                margin-right: 20px

            .header__navigation--mobile
                display: flex

@media screen and (max-width: 520px)
    header
        .header__container
            padding-left: 20px
            padding-right: 20px

            .header__navigation--mobile
                .header__navigation--sidebar
                    width: 100%
                    top: 63px
                    border-left: 0

                    .header__link
                        text-align: center