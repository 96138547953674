.text-teaser-block
    padding-top: 40px
    padding-bottom: 40px
    .text-teaser-block__container
        .numbers-checkbox
            padding-top: 20px
            margin-bottom: 20px

        .text-teaser-block__inner
            .text-teaser-block__item
                display: flex
                flex-direction: row

                margin-bottom: 120px

                &:last-child
                    margin-bottom: 0

                .text-teaser-block__headline-container
                    width: calc(100% / 3)
                    position: relative

                    display: flex
                    flex-direction: column
                    justify-content: flex-end

                    padding-top: 5px
                    padding-bottom: 5px

                    padding-left: 50px
                    //padding-right: 20px

                    margin-left: 30px

                    .count
                        top: 15px
                        left: 20px
                        position: absolute

                        color: $red
                        font-weight: 400
                        font-size: 22px
                        line-height: 50px

                    // @todo Add automatic sloped paddings
                    .text-teaser-block__headline
                        font-size: 25px
                        line-height: 50px
                        font-weight: 700

                        text-align: right

                        &.extra-padding
                            padding-right: 20px

                        p
                            font-size: 25px
                            line-height: 50px
                            font-weight: 700

                            text-align: right

                            span
                                &.padding-0
                                    padding-right: 10px

                                &.padding-1
                                    padding-right: 40px

                                &.padding-2
                                    padding-right: 60px

                    // "Arowed-like" borders
                    &:before
                        content: ''
                        height: 50%
                        width: 100%

                        top: 0
                        left: 0
                        position: absolute

                        border-top: 1px solid rgba($red, 0.6)
                        border-left: 1px solid rgba($red, 0.6)
                        border-right: 1px solid rgba($red, 0.6)

                        transform: skew(30deg)

                    &:after
                        content: ''
                        height: 50%
                        width: 100%

                        bottom: 0
                        left: 0
                        position: absolute

                        border-bottom: 1px solid rgba($red, 0.6)
                        border-left: 1px solid rgba($red, 0.6)
                        border-right: 1px solid rgba($red, 0.6)

                        transform: skew(-30deg)

                .text-teaser-block__description
                    display: flex
                    flex-direction: column
                    justify-content: flex-end

                    width: calc(100% * 2 / 3)
                    padding-left: 150px

                    font-size: 20px
                    font-weight: 400
                    line-height: 35px

                    p
                        font-size: 20px
                        font-weight: 400
                        line-height: 35px

                        padding-bottom: 15px
                        border-bottom: 1px solid rgba($red, 0.5)

                        span.arrow
                            &:before
                                content: ' '
                                display: inline-flex

                                height: 18px
                                width: 15px

                                margin-right: 6px

                                background-image: url('/static/img/arrow-red.svg')
                                background-repeat: no-repeat
                                background-size: 13px

    &.editmode
        .text-teaser-block__headline-container
            border: 1px solid rgba($red, 0.6)
            &:before, &:after
                display: none

            .count
                top: 5px !important
                left: 5px !important

            .text-teaser-block__headline
                z-index: 9995
                text-align: left !important

                &:hover
                    outline: rgb(186, 186, 186) dashed 2px
                    outline-offset: 5px

// Responsive
@media screen and (max-width: 1024px)
    .text-teaser-block
        .text-teaser-block__container
            .text-teaser-block__inner
                .text-teaser-block__item
                    height: auto !important
                    flex-direction: column
                    margin-bottom: 100px

                    .text-teaser-block__headline-container
                        width: 100%
                        margin-left: 15px
                        margin-bottom: 30px

                        padding-top: 15px
                        padding-bottom: 15px

                        .text-teaser-block__headline
                            margin-left: 20px
                            p
                                text-align: left
                                span
                                    &.padding-0,
                                    &.padding-1,
                                    &.padding-2
                                        display: none

                    .text-teaser-block__description
                        width: 100%
                        padding-left: 0

@media screen and (max-width: 720px)
    .text-teaser-block
        .text-teaser-block__container
            .text-teaser-block__inner
                .text-teaser-block__item
                    .text-teaser-block__headline-container
                        .text-teaser-block__headline
                            margin-left: 20px
                            p
                                font-size: 20px
                                line-height: 40px

                        // "Arowed-like" borders
                        &:before
                            width: 95% !important

                        &:after
                            width: 95% !important

                    .text-teaser-block__description
                        width: 100%
                        padding-left: 0

                        p
                            font-size: 16px
                            line-height: 24px

                            span.arrow
                                &:before
                                    height: 15px
                                    width: 12px

