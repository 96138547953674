// Wysiwyg areabrick
.wysiwyg
    padding-top: 40px
    padding-bottom: 40px

    @media screen and (max-width: 720px)
        padding-top: 20px
        padding-bottom: 20px

    .wysiwyg__hash-input-container
        margin-bottom: 30px
        label
            font-size: 16px
            margin-bottom: 10px

        .wysiwyg__hash-input
            font-size: 22px
            display: flex
            flex-direction: row

// Global Wysiwyg content
.wysiwyg__content
    p, a, h1, h2, h3, h4, h5, h6, img
        margin: 0
        padding: 0

    img
        width: 100%
        height: auto
        display: block

    ul
        padding-left: 10px
        list-style: none

        ul
            padding-left: 50px

            @media screen and (max-width: 520px)
                padding-left: 25px

        li
            color: $white
            font-size: 18px
            font-weight: 500
            line-height: 26px

            //display: flex
            //flex-direction: row
            //align-items: flex-start

            margin-bottom: 20px

            padding-left: 20px
            position: relative

            &:before
                top: 0
                left: 0
                position: absolute

                content: ''
                display: flex
                height: 15px
                width: 12px
                margin-top: 2px

                background-image: url(/static/img/arrow-red.svg)
                background-repeat: no-repeat
                background-size: 12px

                flex-shrink: 0

    @media screen and (max-width: 520px)
        ul
            padding-left: 0
            li
                font-size: 16px
                line-height: 30px

                margin-bottom: 10px

                &:before
                    height: 15px
                    width: 12px

                    margin-top: 5px

