footer
    .footer__container
        margin-top: 40px
        padding-top: 22px

        border-top: 1px solid rgba($red, 0.45)

        .footer__navigation-container
            margin-bottom: 60px

            .footer__navigation
                display: flex
                flex-direction: column

                .footer__navigation-link
                    font-size: 20px
                    line-height: 45px
                    font-weight: 700

                    color: rgba($red, 0.45)
                    transition: 0.3s color

                    &:hover, &:focus, &:active
                        color: $red

                    &.active
                        color: $red
                        font-weight: 900

        .footer__logo-container
            display: flex
            align-items: center
            justify-content: center
            .footer__logo
                img
                    width: 55px
                    height: auto

        .footer__message
            text-align: center

            color: $white
            font-size: 12px
            font-weight: 700
            letter-spacing: 5px
            line-height: 1

            padding-top: 27px
            padding-bottom: 27px

        .footer__copyright
            text-align: center
            padding-top: 10px
            padding-bottom: 10px

            font-size: 12px
            font-weight: 700
            line-height: 20px
            color: rgba($white, 0.2)

            border-top: 1px solid rgba($red, 0.45)

// Responsive
@media screen and (max-width: 720px)
    footer
        .footer__container

            .footer__navigation-container
                margin-bottom: 40px
                .footer__navigation
                    .footer__navigation-link
                        text-align: center
                        font-size: 20px
                        line-height: 45px
                        font-weight: 700

            .footer__logo-container

            .footer__message
                line-height: 1.5

            .footer__copyright
