h1
    color: $white
    font-family: 'Spartan', sans-serif
    font-size: 87px
    font-weight: 700
    line-height: 130px

h2
    color: $white
    font-family: 'Spartan', sans-serif
    font-size: 60px
    font-weight: 700
    line-height: 90px

    @media screen and (max-width: 1024px)
        font-size: 50px
        line-height: 60px

    @media screen and (max-width: 520px)
        font-size: 40px
        line-height: 50px

h3
    color: $white
    font-family: 'Spartan', sans-serif
    font-size: 52px
    font-weight: 700
    line-height: 65px

h4
    color: $white
    font-family: 'Spartan', sans-serif
    font-size: 30px
    font-weight: 700
    line-height: 45px

    @media screen and (max-width: 1024px)
        font-size: 25px
        line-height: 35px

    @media screen and (max-width: 520px)
        font-size: 20px
        line-height: 30px

p
    color: $white
    font-family: 'Spartan', sans-serif
    font-size: 22px
    font-weight: 600
    line-height: 50px

    &.subtitle
        font-weight: 700
        line-height: 130px

    @media screen and (max-width: 1024px)
        font-size: 20px
        line-height: 40px

    @media screen and (max-width: 520px)
        font-size: 16px
        line-height: 30px

p, h1, h2, h3, h4, h5, h6
    margin: 0

a
    color: rgba($red, 0.45)
    text-decoration: none !important

    transition: 0.3s color

    &:hover, &:focus, &:active
        color: $red