.header
    .language-switch-container
        min-width: 60px
        select
            opacity: 0
            visibility: hidden

        .select2-container
            width: 100% !important
            .select2-selection
                border: 0
                background: transparent
                .select2-selection__rendered
                    color: $white
                    font-size: 13px
                    //line-height: 1
                    font-weight: 500
                    padding-left: 0

                .select2-selection__arrow
                    display: flex
                    align-items: center
                    justify-content: center
                    transition: 0.3s all

                    &:before
                        content: '\e313'
                        font-family: 'Material Icons'
                        font-weight: bold

                        color: $red
                        font-size: 20px

                    b
                        display: none

                &[aria-expanded='true']
                    .select2-selection__arrow
                        &:before
                            content: '\e316'

    .select2-dropdown
        z-index: 99998
        border-radius: 0
        background-color: $black
        border: 1px solid rgba($red, 0.5) !important
        .select2-results
            .select2-results__options
                .select2-results__option
                    color: rgba($red, 0.5)
                    font-size: 12px
                    font-weight: 700

                    transition: 0.3s all
                    background-color: transparent !important

                    &.select2-results__option--selected
                        color: $red
                        font-weight: 900

                    &:hover
                        color: $red

