.stack-table
    padding-top: 40px
    padding-bottom: 40px

    .stack-table-input-container
        margin-bottom: 30px
        label
            font-size: 16px
            margin-bottom: 10px

        .stack-table-input
            font-size: 22px
            display: flex
            flex-direction: row

    .stack-table__container
        .stack-table__title
            font-size: 25px
            line-height: 35px
            font-weight: 600

            margin-top: 0
            margin-bottom: 50px

        .stack-table__inner
            display: flex
            flex-direction: row

            flex-wrap: nowrap

            .stack
                flex-grow: 1
                width: calc(100% / 5)

                margin-right: 70px

                &:last-child
                    margin-right: 0

                .stack__title
                    font-size: 16px
                    line-height: 35px
                    font-weight: 700

                    border-bottom: 1px solid rgba($red, 0.7)

                .stack__single
                    font-size: 15px
                    line-height: 35px
                    font-weight: 400

                    margin-top: 20px
                    border-bottom: 1px solid rgba($white, 0.3)

// Responsive
@media screen and (max-width: 1250px)
    .stack-table
        .stack-table__container
            .stack-table__inner
                .stack
                    margin-right: 40px

@media screen and (max-width: 991px)
    .stack-table
        padding-top: 20px
        padding-bottom: 20px
        .stack-table__container
            .stack-table__inner
                flex-wrap: wrap
                .stack
                    flex-grow: 1
                    width: calc(100%/3 - 40px)

                    margin-right: 40px
                    margin-bottom: 40px

                    &:nth-child(3n)
                        margin-right: 0

@media screen and (max-width: 720px)
    .stack-table
        .stack-table__container
            .stack-table__title
                font-size: 22px
                margin-bottom: 20px

            .stack-table__inner
                flex-wrap: wrap
                .stack
                    flex-grow: 1
                    width: calc(100%/2 - 40px)

                    margin-right: 40px
                    margin-bottom: 40px

                    &:nth-child(2n)
                        margin-right: 0

                    &:nth-child(3n)
                        margin-right: 40px

                    .stack__title
                        font-size: 15px

                    .stack__single
                        font-size: 14px
                        margin-top: 12px

@media screen and (max-width: 520px)
    .stack-table
        .stack-table__container
            .stack-table__inner
                flex-wrap: wrap
                .stack
                    flex-grow: 1
                    width: 100%

                    margin-right: 0
                    margin-bottom: 40px

                    &:nth-child(3n)
                        margin-right: 0
