@import './vendor'
@import './reset'
@import './variables'
@import './grid'
@import './typography'

@import './components'
@import './snippets'
@import './bricks'
@import './pages'

body, html
    color: $white
    font-family: 'Spartan', sans-serif
    background: $black !important

.hidden
    display: none

// Text selection custom
::-moz-selection
    color: $white
    background: $red

::selection
    color: $white
    background: $red