.spacer
    padding-top: 40px
    padding-bottom: 40px

    .spacer__inner
        width: 100%
        height: 195px

        background-image: url("/static/img/arrow-single.png")
        background-repeat: repeat-x
        background-size: contain

    &.editmode
        &:hover
            .spacer__inner
                outline: 2px dashed #BABABA
                outline-offset: 5px

// Responsive
@media screen and (max-width: 1024px)
    .spacer
        padding-top: 20px
        padding-bottom: 20px

        .spacer__inner
            height: 160px
            background-size: contain

@media screen and (max-width: 720px)
    .spacer
        .spacer__inner
            height: 120px