.form-group
    margin: 0

    label
        margin: 0
        font-size: 22px
        font-weight: 600
        line-height: 40px

        small
            font-size: 14px
            margin-left: 3px

    .form-control
        border: 0
        border-radius: 0
        border-bottom: 1px solid rgba($red, 0.7)

        padding: 0
        outline: 0
        box-shadow: unset
        background: transparent

        height: 57px
        font-size: 20px
        color: rgba($white, 0.7)

        &::placeholder
            color: $black

    .form-error
        ul
            list-style: none
            margin-top: 10px
            margin-bottom: 0

            li
                color: $red
                font-size: 14px
                line-height: 24px

@media screen and (max-width: 1024px)
    .form-group

        label
            font-size: 18px
            line-height: 20px

            small
                font-size: 12px

        .form-control
            height: 45px
            font-size: 18px

        .form-error
            ul
                li
                    font-size: 12px
                    line-height: 20px

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
    background-color: transparent !important
    -webkit-box-shadow: 0 0 0 30px white inset !important
