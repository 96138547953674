.text-teaser
    padding-top: 40px
    padding-bottom: 40px
    .text-teaser__container
        position: relative

        .text-teaser__inner
            padding-top: 80px
            padding-bottom: 80px

            // Values set because of "skew" transform
            padding-left: 160px
            padding-right: 120px

            .teaser
                display: inline-flex
                align-items: center
                justify-content: center

            .text-teaser__text
                h2
                    font-size: 60px
                    font-weight: 700
                    line-height: 65px

            .text-teaser__description
                font-size: 20px
                font-weight: 400
                line-height: 35px

                display: inline-flex

                p
                    font-size: 20px
                    font-weight: 400
                    line-height: 35px

                    span.arrow
                        &:before
                            content: ' '
                            display: inline-flex

                            height: 18px
                            width: 15px

                            margin-right: 6px

                            background-image: url('/static/img/arrow-red.svg')
                            background-repeat: no-repeat
                            background-size: 13px

        // "Arowed-like" borders
        &:before
            content: ''
            height: 50%
            width: calc(100% - 100px)

            top: 0
            left: 50px
            position: absolute

            border-top: 1px solid rgba($red, 0.6)
            border-left: 1px solid rgba($red, 0.6)
            border-right: 1px solid rgba($red, 0.6)

            transform: skew(30deg)

        &:after
            content: ''
            height: 50%
            width: calc(100% - 100px)

            bottom: 0
            left: 50px
            position: absolute

            border-bottom: 1px solid rgba($red, 0.6)
            border-left: 1px solid rgba($red, 0.6)
            border-right: 1px solid rgba($red, 0.6)

            transform: skew(-30deg)

@media screen and (max-width: 1250px)
    .text-teaser
        .text-teaser__container

            .text-teaser__inner
                padding-top: 50px
                padding-bottom: 50px

                .teaser

                .text-teaser__text
                    h2
                        font-size: 50px
                        line-height: 60px

                .text-teaser__description

            // "Arowed-like" borders
            &:before
                width: 82%
                left: 100px

            &:after
                width: 82%
                left: 100px

@media screen and (max-width: 1024px)
    .text-teaser
        .text-teaser__container

            .text-teaser__inner
                padding: 0
                flex-direction: column

                .teaser
                    max-width: 100% !important
                    margin-bottom: 40px

                    justify-content: flex-start

                    &:last-child
                        margin-bottom: 0

                .text-teaser__text
                    padding-top: 40px
                    padding-bottom: 40px

                    padding-right: 80px
                    padding-left: 100px

                    width: 65%

                    h2
                        font-size: 40px
                        line-height: 50px

                    &:before
                        content: ''
                        height: 50%
                        width: calc(100% - 130px)

                        top: 0
                        left: 65px
                        position: absolute

                        border-top: 1px solid rgba($red, 0.6)
                        border-left: 1px solid rgba($red, 0.6)
                        border-right: 1px solid rgba($red, 0.6)

                        transform: skew(30deg)

                    &:after
                        content: ''
                        height: 50%
                        width: calc(100% - 130px)

                        bottom: 0
                        left: 65px
                        position: absolute

                        border-bottom: 1px solid rgba($red, 0.6)
                        border-left: 1px solid rgba($red, 0.6)
                        border-right: 1px solid rgba($red, 0.6)

                        transform: skew(-30deg)

                .text-teaser__description
                    font-size: 20px
                    font-weight: 400
                    line-height: 35px

                    display: inline-flex

                    padding-bottom: 15px
                    border-bottom: 1px solid rgba($red, 0.6)

                    p
                        font-size: 20px
                        font-weight: 400
                        line-height: 35px

                        span.arrow
                            &:before
                                height: 18px
                                width: 15px

                                margin-right: 6px

            // "Arowed-like" borders
            &:before,
            &:after
                display: none

@media screen and (max-width: 720px)
    .text-teaser
        .text-teaser__container
            .text-teaser__inner
                .teaser
                    margin-bottom: 25px

                .text-teaser__text
                    padding-top: 30px
                    padding-bottom: 30px

                    padding-right: 80px
                    padding-left: 80px

                    width: 100%

                    h2
                        font-size: 30px
                        line-height: 40px

                    &:before
                        width: calc(100% - 100px)
                        left: 50px

                    &:after
                        width: calc(100% - 100px)
                        left: 50px

                .text-teaser__description
                    p
                        font-size: 16px
                        line-height: 24px

                        span.arrow
                            &:before
                                height: 15px
                                width: 12px

@media screen and (max-width: 520px)
    .text-teaser
        .text-teaser__container
            .text-teaser__inner
                .text-teaser__text
                    padding-top: 20px
                    padding-bottom: 20px
                    padding-left: 60px
                    padding-right: 60px

                    h2
                        font-size: 20px
                        line-height: 30px
