.page-loader
    position: fixed

    top: 0
    left: 0

    width: 100%
    height: 100%

    background: $black
    z-index: 99999

    display: flex
    align-items: center
    justify-content: center

    opacity: 1
    visibility: visible
    transition: 0.76s all

    pointer-events: none

    &.hidden
        opacity: 0
        visibility: hidden

    .loader-container
        display: flex

        svg
            width: 110px
            height: 110px

    @media screen and (max-width: 991px)
        .loader-container
            svg
                width: 90px
                height: 90px