.top-hero
    padding-top: 130px
    padding-bottom: 130px

    margin-bottom: 40px

    position: relative

    .container
        position: relative
        z-index: 2

    .top-hero__text
        color: $white
        font-size: 60px
        line-height: 80px
        font-weight: 700

        margin-bottom: 12px

    .top-hero__description
        color: $white
        font-size: 22px
        line-height: 50px
        font-weight: 700

    // Background
    &:before
        //content: '\ea50'
        //font-family: 'Material Icons'
        content: ''
        display: block

        //font-size: 80px
        //color: $white

        top: 0
        left: 0
        position: absolute

        width: 100%
        height: 100%

        z-index: 1

        background-image: url("/static/img/arrows.svg")
        background-repeat: repeat-x
        background-size: contain

// Responsive
@media screen and (max-width: 1200px)
    .top-hero
        padding-top: 100px
        padding-bottom: 100px

        // Background
        &:before
            background-size: cover

@media screen and (max-width: 991px)
    .top-hero
        .top-hero__text
            font-size: 50px
            line-height: 60px

        .top-hero__description
            font-size: 20px
            line-height: 40px

@media screen and (max-width: 720px)
    .top-hero
        padding-top: 80px
        padding-bottom: 80px

        margin-bottom: 20px

        .top-hero__text
            font-size: 40px
            line-height: 50px

@media screen and (max-width: 520px)
    .top-hero
        padding-top: 40px
        padding-bottom: 40px
        .top-hero__text
            font-size: 30px
            line-height: 40px

        .top-hero__description
            font-size: 18px
            line-height: 26px